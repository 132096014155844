import React, { Component } from "react";
import { Row, Col } from "antd";
import {Link} from "gatsby";
import "./thank-you.css"

class Thankyou extends Component {
  render() {
    return (
      <>
        <div className="">
          <div>
            <section className=" thank-you ">
              <div className="thank-container">
                <Row
                  className="text-center flex-wrap"
                  align="middle"
                  type="flex"
                >
                  <Col lg={24} xs={24} className="text-center">
                    <div className="thank-you-content">
                      <h1 className="">Thank you for your interest in HK</h1>
                      <h3>
                        Your HK partner account is under review. Our support
                        team will process your application soon and get in touch
                        with you if needed. You will receive a confirmation
                        email with your login details post verification.
                      </h3>
                      <Link style={{ color: "#fff" }} to="/">
                        <button className="button primary-fill mt-20">
                          Back To Home
                        </button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default Thankyou;
